import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Bkg } from "../../components/content/bkg";
import { HomeCalculatorSection } from "./section/calculator";
import { HomeComplianceSection } from "./section/compliance";
import { HomeDisclaimer } from "./section/disclaimer";
import { HomeDownloadAppSection } from "./section/download-app";
import { HomeGetStartedSection } from "./section/get-started";
import { HomeGetStartedPopup } from "./section/get-started-popup";
import { HomeGrowthSection } from "./section/growth";
import { HomeHeroSection } from "./section/hero";
import { HomeInvestmentProcessSection } from "./section/investment-process";
import { HomeJoinSection } from "./section/join";
import { HomeMarketplaceSampleSection } from "./section/marketplace-sample";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CHECK_DEVICE } from "../../graphql/queries/device";
import HomeTestimonialSlider from "./section/testimonials";
import UnderMaintenance from "../../components/modal/under-maintenance";
import { useSearchParams } from "react-router-dom";
import PromoPopupPage from "../promo/promo";
import { GET_CREATOR_BY_COUPON } from "../../graphql/queries/coupon";

export function HomePage() {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPromo, setShowPromo] = useState(false);
  const [searchParams] = useSearchParams();
  const [getCreatorByCoupon, { data: creatorData, loading: creatorLoading }] =
    useLazyQuery(GET_CREATOR_BY_COUPON, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const promoCode = searchParams.get("promo");
    if (promoCode) {
      getCreatorByCoupon({ variables: { code: promoCode } }); // Call the query here
      setShowPromo(true);
    }
  }, [searchParams, getCreatorByCoupon]);

  useEffect(() => {
    FingerprintJS.load().then(async (fp) => {
      const result = await fp.get();
      setDeviceId(result.visitorId);
    });
  }, []);

  const { data, loading, error } = useQuery(CHECK_DEVICE, {
    variables: { deviceId },
    skip: !deviceId,
  });

  useEffect(() => {
    if (data && deviceId) {
      if (data.checkDevice) {
        setShowComingSoon(false);
      } else {
        setShowComingSoon(true);
      }
    }
    if (error) {
      setErrorMessage(error.message);
    }
  }, [data, loading, error, deviceId]);

  useEffect(() => {
    const agreed = localStorage.getItem("disclaimer-agreed");
    if (agreed) {
      setTimeout(() => {
        checkDevice();
      }, 1000);
    }
  }, [data, deviceId]);

  const checkDevice = () => {
    if (data && deviceId) {
      if (data.checkDevice) {
        setShowComingSoon(false);
      } else {
        setShowComingSoon(true);
      }
    }
  };

  const handleCloseUnderMaintenance = () => {
    setErrorMessage("");
  };

  return (
    <>
      <Helmet>
        <title>PropNerd | Home</title>
        <meta
          name="description"
          content="PropNerd harnesses the power of blockchain technology to break down the barriers of traditional real estate investing."
        />
      </Helmet>

      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />

      <Nav />

      <Bkg />

      <Content className="home">
        <HomeHeroSection />
        <HomeDisclaimer onHide={checkDevice} />
        {showPromo && (
          <PromoPopupPage
            show={showPromo}
            setShow={setShowPromo}
            data={creatorData?.getCreatorByCoupon}
            loading={creatorLoading}
          />
        )}
        <HomeGetStartedPopup
          show={showComingSoon}
          setShow={setShowComingSoon}
        />
        <HomeGrowthSection />
        <HomeComplianceSection />
        <HomeInvestmentProcessSection />
        <HomeMarketplaceSampleSection />
        <HomeCalculatorSection />
        <HomeJoinSection />
        <HomeTestimonialSlider />
        <HomeDownloadAppSection />
        <HomeGetStartedSection />
      </Content>

      <Footer />
    </>
  );
}
