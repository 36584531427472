import { gql } from "@apollo/client";

export const QUERY_GET_PROPERTY_LIST = gql`
  query getProperties($take: Int, $skip: Int, $where: WhereInput) {
    publicProperties(
      paginationOptions: { take: $take, skip: $skip }
      where: $where
    ) {
      id
      title
      description
      excerpt
      code
      price
      location
      bed
      status
      bath
      sqFootage
      transactionFee
      platformFee
      slug
      isFunded
      order
      manualFunded
      manualFundedDays
      finalInvestorsCount
      type {
        id
      }
      city {
        name
      }
      amenities {
        title
      }
      timelineEvents {
        title
        description
      }
      financials {
        projectedAnnualRoIPercentage
        projectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue
        annualRent
        effectiveDate
        endDate
      }
      rents {
        date
        rent
        isPaid
        isDisbursed
      }
      images {
        id
        ext
        order
      }
      pledges {
        id
        status
        amount
      }
      createdAt
    }
  }
`;

export const QUERY_GET_PROPERTY_NAMES = gql`
  query getPropertyNames($take: Int, $skip: Int, $where: WhereInput) {
    publicProperties(
      paginationOptions: { take: $take, skip: $skip }
      where: $where
    ) {
      id
      title
    }
  }
`;

export const QUERY_GET_PROPERTY = gql`
  query getProperty($id: String!) {
    publicProperty(id: $id) {
      id
      title
      description
      code
      price
      location
      transactionFee
      platformFee
      isRented
      isFunded
      bed
      bath
      sqFootage
      manualFunded
      manualFundedDays
      finalInvestorsCount
      city {
        name
        excerpt
        description
        propertyDescription
        location
      }
      images {
        id
        ext
        order
      }
      videos {
        id
        ext
        order
      }
      documents {
        id
        filename
        ext
      }
      amenities {
        title
      }
      timelineEvents {
        title
        description
        date
      }
      financials {
        projectedAnnualRoIPercentage
        projectedAnnualRoIValue
        year3ProjectedAnnualRoIPercentage
        year3ProjectedAnnualRoIValue
        annualRent
        effectiveDate
        endDate
      }
      rents {
        date
        rent
        isPaid
        isDisbursed
      }
      pledges {
        id
        amount
        status
      }
      createdAt
    }
  }
`;
