import { gql } from "@apollo/client";

export const GET_CREATOR_BY_COUPON = gql`
  query GetCreatorByCoupon($code: String!) {
    getCreatorByCoupon(code: $code) {
      isPromoValid
      code
      creator {
        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
