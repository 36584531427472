/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** Decimal custom scalar type */
  Decimal: { input: any; output: any };
  GeoJSONPointScalar: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
};

export type Admin = {
  __typename?: "Admin";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  role: Role;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type AuthLoginRequest = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type CmsDataOutput = {
  __typename?: "CMSDataOutput";
  languages: Array<Language>;
  values: Array<CmsValueJson>;
};

export type CmsLang = {
  __typename?: "CMSLang";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsPage = {
  __typename?: "CMSPage";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  sections: Array<CmsSection>;
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsSection = {
  __typename?: "CMSSection";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  page: CmsPage;
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsValue = {
  __typename?: "CMSValue";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  language: CmsLang;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  page: CmsPage;
  section: CmsSection;
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["String"]["output"];
};

export type CmsValueInput = {
  key: Scalars["String"]["input"];
  languageName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  pageName: Scalars["String"]["input"];
  sectionName: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type CmsValueJson = {
  __typename?: "CMSValueJson";
  key: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  page: Scalars["String"]["output"];
  section: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Card = {
  __typename?: "Card";
  brand: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  display_brand: Scalars["String"]["output"];
  exp_month: Scalars["Float"]["output"];
  exp_year: Scalars["Float"]["output"];
  funding: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
};

export type CheckAndSaveDeviceInput = {
  deviceId: Scalars["String"]["input"];
  deviceType: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
};

export type CheckVerificationOptionsResponse = {
  __typename?: "CheckVerificationOptionsResponse";
  verifiedEmail: Scalars["Boolean"]["output"];
  verifiedPhone: Scalars["Boolean"]["output"];
};

export type City = {
  __typename?: "City";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  location?: Maybe<Scalars["GeoJSONPointScalar"]["output"]>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  propertyDescription?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type Coupon = {
  __typename?: "Coupon";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creator?: Maybe<Investor>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  expirationDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  incentive: CouponIncentive;
  referrals?: Maybe<Array<Referral>>;
  source?: Maybe<Array<Referral>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CouponCreatorResponse = {
  __typename?: "CouponCreatorResponse";
  code?: Maybe<Scalars["String"]["output"]>;
  creator?: Maybe<CreatorResponse>;
  isPromoValid: Scalars["Boolean"]["output"];
};

export type CouponDto = {
  __typename?: "CouponDto";
  code: Scalars["String"]["output"];
  incentive: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export enum CouponIncentive {
  BonusCredit = "BONUS_CREDIT",
  Discount = "DISCOUNT",
  Min_100 = "MIN_100",
}

export type CreatorResponse = {
  __typename?: "CreatorResponse";
  user?: Maybe<UserResponse>;
};

export type Currency = {
  __typename?: "Currency";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Customer = {
  __typename?: "Customer";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  object: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type DashboardAnnualAppreciationStats = {
  __typename?: "DashboardAnnualAppreciationStats";
  annualAppreciation: Scalars["Float"]["output"];
  property: Property;
  year: Scalars["Float"]["output"];
};

export type DashboardAnnualReturnStats = {
  __typename?: "DashboardAnnualReturnStats";
  annualReturn: Scalars["Float"]["output"];
  property: Property;
};

export type DashboardDepositStats = {
  __typename?: "DashboardDepositStats";
  amount: Scalars["Float"]["output"];
  date: Scalars["String"]["output"];
};

export type DashboardInvestmentStats = {
  __typename?: "DashboardInvestmentStats";
  percentage: Scalars["Float"]["output"];
  property: Property;
};

export type DashboardPledgeStats = {
  __typename?: "DashboardPledgeStats";
  amount: Scalars["Float"]["output"];
  date: Scalars["String"]["output"];
};

export type DashboardRequest = {
  endDate: Scalars["String"]["input"];
  startDate: Scalars["String"]["input"];
};

export type DashboardResponse = {
  __typename?: "DashboardResponse";
  annualAppreciations: Array<Array<DashboardAnnualAppreciationStats>>;
  annualReturns: Array<DashboardAnnualReturnStats>;
  deposits: Array<DashboardDepositStats>;
  investments: Array<DashboardInvestmentStats>;
  pledges: Array<DashboardPledgeStats>;
};

export type DepositCryptoResponse = {
  __typename?: "DepositCryptoResponse";
  cryptoAddress: Scalars["String"]["output"];
  tag?: Maybe<Scalars["String"]["output"]>;
};

export type Device = {
  __typename?: "Device";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  fcmToken?: Maybe<Scalars["String"]["output"]>;
  hideNotificationBar?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  model: Scalars["String"]["output"];
  osVersion: Scalars["String"]["output"];
  otp?: Maybe<Scalars["String"]["output"]>;
  otpExpiration?: Maybe<Scalars["DateTime"]["output"]>;
  status: Scalars["String"]["output"];
  turnNotificationsOn?: Maybe<Scalars["Boolean"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type FilterInput = {
  field: Scalars["String"]["input"];
  operator: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type GetBalanceResponse = {
  __typename?: "GetBalanceResponse";
  getBalance: Scalars["Float"]["output"];
  totalDepositSum?: Maybe<Scalars["Float"]["output"]>;
};

export type GetNotificationStatusResponse = {
  __typename?: "GetNotificationStatusResponse";
  hideNotificationBar: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  turnNotificationsOn: Scalars["Boolean"]["output"];
};

export type GetPaymentIntentStatus = {
  __typename?: "GetPaymentIntentStatus";
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export enum HubSpotContactLifeCycleStatus {
  Customer = "CUSTOMER",
  Evangelist = "EVANGELIST",
  Lead = "LEAD",
  MarketingQualifiedLead = "MARKETING_QUALIFIED_LEAD",
  Opportunity = "OPPORTUNITY",
  Other = "OTHER",
  SalesQualifiedLead = "SALES_QUALIFIED_LEAD",
  Subscriber = "SUBSCRIBER",
}

export enum HubSpotDealStatus {
  ClosedLost = "CLOSED_LOST",
  Deposited = "DEPOSITED",
  Invested = "INVESTED",
  SignedUp = "SIGNED_UP",
  Verified = "VERIFIED",
}

export type HubSpotSync = {
  __typename?: "HubSpotSync";
  amount?: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dealId?: Maybe<Scalars["String"]["output"]>;
  dealStatus?: Maybe<HubSpotDealStatus>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  firstDepositDate?: Maybe<Scalars["DateTime"]["output"]>;
  firstInvestmentDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  investor: Investor;
  status: HubSpotSyncStatus;
  tries?: Maybe<Scalars["Float"]["output"]>;
  type: HubSpotSyncType;
  updatedAt: Scalars["DateTime"]["output"];
  verifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export enum HubSpotSyncStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export enum HubSpotSyncType {
  Deposit = "DEPOSIT",
  Investment = "INVESTMENT",
}

export enum InitiatorType {
  Admin = "Admin",
  Investor = "Investor",
}

export type Investor = {
  __typename?: "Investor";
  annualInvestmentLimit: Scalars["BigInt"]["output"];
  balance: Scalars["BigInt"]["output"];
  coupon?: Maybe<Coupon>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  firstDepositDate?: Maybe<Scalars["DateTime"]["output"]>;
  firstInvestmentDate?: Maybe<Scalars["DateTime"]["output"]>;
  hideNotificationBar?: Maybe<Scalars["Boolean"]["output"]>;
  hubSpotDealId?: Maybe<Scalars["String"]["output"]>;
  hubSpotDealStatus?: Maybe<HubSpotDealStatus>;
  hubSpotSync?: Maybe<HubSpotSync>;
  hubSpotSyncStatus: HubSpotSyncStatus;
  hubSpotSyncTries?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  investmentApproach?: Maybe<Scalars["String"]["output"]>;
  investmentDuration?: Maybe<Scalars["Float"]["output"]>;
  invitesSent?: Maybe<Array<InvestorInvite>>;
  isDemo: Scalars["Boolean"]["output"];
  referrals?: Maybe<Array<Referral>>;
  rewardBalance?: Maybe<Scalars["Float"]["output"]>;
  solanaPublicKey?: Maybe<Scalars["String"]["output"]>;
  solanaSecretKey?: Maybe<Scalars["String"]["output"]>;
  solanaWalletAddress?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  sumsubApplicantId?: Maybe<Scalars["String"]["output"]>;
  sumsubButtonIds?: Maybe<Array<Scalars["String"]["output"]>>;
  sumsubPrivateComment?: Maybe<Scalars["String"]["output"]>;
  sumsubPublicComment?: Maybe<Scalars["String"]["output"]>;
  sumsubRejectLabels?: Maybe<Array<Scalars["String"]["output"]>>;
  sumsubSandboxMode?: Maybe<Scalars["Boolean"]["output"]>;
  turnNotificationsOn?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  veriffSessionId?: Maybe<Scalars["String"]["output"]>;
  veriffSessionUrl?: Maybe<Scalars["String"]["output"]>;
  verifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type InvestorAuthLoginResponse = {
  __typename?: "InvestorAuthLoginResponse";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  deviceStatus?: Maybe<Scalars["String"]["output"]>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type InvestorInvite = {
  __typename?: "InvestorInvite";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  invitedEmail: Scalars["String"]["output"];
  inviter: Investor;
  status: InvitationStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InvestorInviteResponseDto = {
  __typename?: "InvestorInviteResponseDto";
  invites?: Maybe<Array<InvestorInvite>>;
  message: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type InvestorLead = {
  __typename?: "InvestorLead";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  expectInvestmentAmount?: Maybe<Scalars["Int"]["output"]>;
  fullName: Scalars["String"]["output"];
  hubSpotLifecycleStage?: Maybe<HubSpotContactLifeCycleStatus>;
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  intercomLeadConversationId?: Maybe<Scalars["String"]["output"]>;
  intercomLeadId?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  preferredProperty?: Maybe<Property>;
  updatedAt: Scalars["DateTime"]["output"];
  utm_campaign?: Maybe<Scalars["String"]["output"]>;
  utm_content?: Maybe<Scalars["String"]["output"]>;
  utm_id?: Maybe<Scalars["String"]["output"]>;
  utm_medium?: Maybe<Scalars["String"]["output"]>;
  utm_source?: Maybe<Scalars["String"]["output"]>;
  utm_term?: Maybe<Scalars["String"]["output"]>;
  utm_url: Scalars["String"]["output"];
};

export type InvestorLeadInput = {
  email: Scalars["String"]["input"];
  expectInvestmentAmount?: InputMaybe<Scalars["Int"]["input"]>;
  fullName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  preferredPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  utm_campaign?: InputMaybe<Scalars["String"]["input"]>;
  utm_content?: InputMaybe<Scalars["String"]["input"]>;
  utm_id?: InputMaybe<Scalars["String"]["input"]>;
  utm_medium?: InputMaybe<Scalars["String"]["input"]>;
  utm_source?: InputMaybe<Scalars["String"]["input"]>;
  utm_term?: InputMaybe<Scalars["String"]["input"]>;
  utm_url: Scalars["String"]["input"];
};

export enum InvitationStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Expired = "Expired",
  Invested = "Invested",
  Pending = "Pending",
  Withdrawn = "Withdrawn",
}

export type Language = {
  __typename?: "Language";
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addPasswordReset: PasswordResetResponse;
  addPropertyBookmark: Scalars["Boolean"]["output"];
  changePassword: Scalars["Boolean"]["output"];
  confirmMultiplePledges: Array<PropertyPledge>;
  confirmPledge: PropertyPledge;
  createTicket: Scalars["String"]["output"];
  deleteAccount: Scalars["Boolean"]["output"];
  deletePledge: PropertyPledge;
  depositCrypto: DepositCryptoResponse;
  depositFiat?: Maybe<PaymentIntent>;
  detachPaymentMethod: PaymentMethod;
  generateSetupIntent: SetupIntentResponse;
  login: InvestorAuthLoginResponse;
  pledgeAmountToProperty: PropertyPledge;
  redeemCoupon: Scalars["Boolean"]["output"];
  register: UserRegisterResponse;
  registerInterest: Scalars["String"]["output"];
  removePropertyBookmark: Scalars["Boolean"]["output"];
  requestAccountDeletion: Scalars["Boolean"]["output"];
  resendOTPToPhone: ResendOtpResponse;
  resetPassword: ResetPasswordResponse;
  saveFcmToken: SaveFcmTokenResponse;
  saveMarketingStatus: Scalars["Boolean"]["output"];
  saveOrUpdateCMSValues: Scalars["Boolean"]["output"];
  sendInvite: InvestorInviteResponseDto;
  sendOTP: Scalars["String"]["output"];
  submitProperty: Property;
  updateHideNotificationBar: SaveFcmTokenResponse;
  updateMe: User;
  updateUser: UpdateUserResponse;
  verifyOTP: VerifyOtpResponse;
  verifyOTPToPhone: UpdateUserResponse;
};

export type MutationAddPasswordResetArgs = {
  data: PasswordResetRequest;
};

export type MutationAddPropertyBookmarkArgs = {
  propertyId: Scalars["String"]["input"];
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars["String"]["input"];
  oldPassword: Scalars["String"]["input"];
};

export type MutationConfirmMultiplePledgesArgs = {
  pledges: Array<PledgeInput>;
};

export type MutationConfirmPledgeArgs = {
  amount: Scalars["Int"]["input"];
  pledgeId: Scalars["String"]["input"];
};

export type MutationCreateTicketArgs = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  tokens: Scalars["Float"]["input"];
};

export type MutationDeletePledgeArgs = {
  pledgeId: Scalars["String"]["input"];
};

export type MutationDepositCryptoArgs = {
  amount: Scalars["Int"]["input"];
  currency: Scalars["String"]["input"];
};

export type MutationDepositFiatArgs = {
  amount: Scalars["Int"]["input"];
  currency: Scalars["String"]["input"];
  isApp?: InputMaybe<Scalars["Boolean"]["input"]>;
  paymentMethodId: Scalars["String"]["input"];
};

export type MutationDetachPaymentMethodArgs = {
  paymentMethodId: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  data: AuthLoginRequest;
  deviceData: CheckAndSaveDeviceInput;
};

export type MutationPledgeAmountToPropertyArgs = {
  amount: Scalars["Int"]["input"];
  propertyId: Scalars["String"]["input"];
};

export type MutationRedeemCouponArgs = {
  amount: Scalars["Float"]["input"];
};

export type MutationRegisterArgs = {
  data: UserRegisterRequest;
  deviceData: CheckAndSaveDeviceInput;
};

export type MutationRegisterInterestArgs = {
  data: InvestorLeadInput;
};

export type MutationRemovePropertyBookmarkArgs = {
  propertyId: Scalars["String"]["input"];
};

export type MutationRequestAccountDeletionArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResendOtpToPhoneArgs = {
  phone: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationSaveFcmTokenArgs = {
  deviceId: Scalars["String"]["input"];
  fcmToken: Scalars["String"]["input"];
  turnNotificationsOn: Scalars["Boolean"]["input"];
};

export type MutationSaveMarketingStatusArgs = {
  marketingEmails: Scalars["Boolean"]["input"];
};

export type MutationSaveOrUpdateCmsValuesArgs = {
  input: Array<CmsValueInput>;
};

export type MutationSendInviteArgs = {
  invitedEmail: Scalars["String"]["input"];
};

export type MutationSendOtpArgs = {
  deviceId: Scalars["String"]["input"];
  method: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationSubmitPropertyArgs = {
  data: PropertySubmitRequest;
};

export type MutationUpdateHideNotificationBarArgs = {
  deviceId: Scalars["String"]["input"];
  hideNotificationBar: Scalars["Boolean"]["input"];
};

export type MutationUpdateMeArgs = {
  data: UserUpdateRequest;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateRequest;
};

export type MutationVerifyOtpArgs = {
  deviceId: Scalars["String"]["input"];
  method: Scalars["String"]["input"];
  otp: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationVerifyOtpToPhoneArgs = {
  otp: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

/** Common pagination request options */
export type PaginationRequest = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Partner = {
  __typename?: "Partner";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type PasswordResetRequest = {
  email: Scalars["String"]["input"];
};

export type PasswordResetResponse = {
  __typename?: "PasswordResetResponse";
  id: Scalars["String"]["output"];
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  client_secret?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  next_action?: Maybe<PaymentIntentNextAction>;
  status: Scalars["String"]["output"];
};

export type PaymentIntentNextAction = {
  __typename?: "PaymentIntentNextAction";
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  card?: Maybe<Card>;
  created: Scalars["Float"]["output"];
  customer: Customer;
  id: Scalars["String"]["output"];
  sepa_debit?: Maybe<SepaDebit>;
  type: Scalars["String"]["output"];
};

export type PledgeInput = {
  amount: Scalars["Float"]["input"];
  id: Scalars["String"]["input"];
};

export type PortfolioStats = {
  __typename?: "PortfolioStats";
  annualRentYield: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  monthlyIncome: Scalars["Int"]["output"];
  numProperties: Scalars["Int"]["output"];
  occupancyRate: Scalars["Int"]["output"];
  portfolioValue: Scalars["Int"]["output"];
  totalAppreciation: Scalars["Int"]["output"];
  totalDeposits: Scalars["Int"]["output"];
  totalInvestment: Scalars["Int"]["output"];
  totalRentalIncome: Scalars["Int"]["output"];
};

export type Property = {
  __typename?: "Property";
  address: Scalars["String"]["output"];
  agreementPrice: Scalars["BigInt"]["output"];
  amenities?: Maybe<Array<PropertyAmenity>>;
  bath: Scalars["Int"]["output"];
  bed: Scalars["Int"]["output"];
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  city: City;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  documents?: Maybe<Array<PropertyFile>>;
  excerpt: Scalars["String"]["output"];
  finalInvestorsCount: Scalars["Int"]["output"];
  financials: Array<PropertyFinancials>;
  fundingCompleteDate?: Maybe<Scalars["DateTime"]["output"]>;
  fundingDeadlineDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  images?: Maybe<Array<PropertyFile>>;
  isFunded: Scalars["Boolean"]["output"];
  isRented: Scalars["Boolean"]["output"];
  leads?: Maybe<Array<InvestorLead>>;
  location?: Maybe<Scalars["GeoJSONPointScalar"]["output"]>;
  manualFunded?: Maybe<Scalars["BigInt"]["output"]>;
  manualFundedDays?: Maybe<Scalars["BigInt"]["output"]>;
  minimumInvestment: Scalars["Int"]["output"];
  order: Scalars["Int"]["output"];
  platformFee: Scalars["BigInt"]["output"];
  pledges: Array<PropertyPledge>;
  price: Scalars["BigInt"]["output"];
  rents: Array<PropertyRent>;
  serviceCharges: Scalars["BigInt"]["output"];
  slug: Scalars["String"]["output"];
  solanaMintAddress?: Maybe<Scalars["String"]["output"]>;
  sqFootage: Scalars["Int"]["output"];
  status: PropertyStatus;
  timelineEvents: Array<PropertyTimeline>;
  title: Scalars["String"]["output"];
  transactionFee: Scalars["BigInt"]["output"];
  type?: Maybe<PropertyType>;
  updatedAt: Scalars["DateTime"]["output"];
  videos?: Maybe<Array<PropertyFile>>;
};

export type PropertyAmenity = {
  __typename?: "PropertyAmenity";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyBookmark = {
  __typename?: "PropertyBookmark";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type PropertyFile = {
  __typename?: "PropertyFile";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  ext: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  propertyDocument: Property;
  propertyImage: Property;
  propertyVideo: Property;
  size: Scalars["BigInt"]["output"];
  type: PropertyFileType;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PropertyFileType {
  Document = "Document",
  Image = "Image",
  Video = "Video",
}

export type PropertyFinancials = {
  __typename?: "PropertyFinancials";
  annualAppreciation: Scalars["Int"]["output"];
  annualManagementAndMaintenanceCharges: Scalars["BigInt"]["output"];
  annualRent: Scalars["BigInt"]["output"];
  annualServiceCharges: Scalars["BigInt"]["output"];
  annualizedReturn: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  effectiveDate: Scalars["DateTime"]["output"];
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  estimated3YrRoI?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  projectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  projectedAnnualRoIValue: Scalars["BigInt"]["output"];
  projectedGrossYield: Scalars["Int"]["output"];
  projectedNetYield: Scalars["Int"]["output"];
  property: Property;
  updatedAt: Scalars["DateTime"]["output"];
  year2ProjectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["output"];
  year3ProjectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["output"];
};

export type PropertyPledge = {
  __typename?: "PropertyPledge";
  amount: Scalars["BigInt"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  status: PropertyPledgeStatus;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export enum PropertyPledgeStatus {
  Cancelled = "Cancelled",
  Confirmed = "Confirmed",
  Pending = "Pending",
  Shareholder = "Shareholder",
}

export type PropertyRent = {
  __typename?: "PropertyRent";
  createdAt: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isDisbursed: Scalars["Boolean"]["output"];
  isPaid: Scalars["Boolean"]["output"];
  property: Property;
  rent: Scalars["BigInt"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PropertyStatus {
  Active = "Active",
  ComingSoon = "ComingSoon",
  Completed = "Completed",
  Inactive = "Inactive",
  Pending = "Pending",
}

export type PropertySubmitRequest = {
  address: Scalars["String"]["input"];
  bedrooms: Scalars["Int"]["input"];
  city: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  phone: Scalars["String"]["input"];
};

export type PropertyTimeline = {
  __typename?: "PropertyTimeline";
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  property: Property;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyType = {
  __typename?: "PropertyType";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyWithRoI = {
  __typename?: "PropertyWithRoI";
  address: Scalars["String"]["output"];
  agreementPrice: Scalars["BigInt"]["output"];
  amenities?: Maybe<Array<PropertyAmenity>>;
  bath: Scalars["Int"]["output"];
  bed: Scalars["Int"]["output"];
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  city: City;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  documents?: Maybe<Array<PropertyFile>>;
  estimated3YrRoI: Scalars["Float"]["output"];
  excerpt: Scalars["String"]["output"];
  finalInvestorsCount: Scalars["Int"]["output"];
  financials: Array<PropertyFinancials>;
  fundingCompleteDate?: Maybe<Scalars["DateTime"]["output"]>;
  fundingDeadlineDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  images?: Maybe<Array<PropertyFile>>;
  isFunded: Scalars["Boolean"]["output"];
  isRented: Scalars["Boolean"]["output"];
  leads?: Maybe<Array<InvestorLead>>;
  location?: Maybe<Scalars["GeoJSONPointScalar"]["output"]>;
  manualFunded?: Maybe<Scalars["BigInt"]["output"]>;
  manualFundedDays?: Maybe<Scalars["BigInt"]["output"]>;
  minimumInvestment: Scalars["Int"]["output"];
  order: Scalars["Int"]["output"];
  platformFee: Scalars["BigInt"]["output"];
  pledges: Array<PropertyPledge>;
  price: Scalars["BigInt"]["output"];
  rents: Array<PropertyRent>;
  serviceCharges: Scalars["BigInt"]["output"];
  slug: Scalars["String"]["output"];
  solanaMintAddress?: Maybe<Scalars["String"]["output"]>;
  sqFootage: Scalars["Int"]["output"];
  status: PropertyStatus;
  timelineEvents: Array<PropertyTimeline>;
  title: Scalars["String"]["output"];
  transactionFee: Scalars["BigInt"]["output"];
  type?: Maybe<PropertyType>;
  updatedAt: Scalars["DateTime"]["output"];
  videos?: Maybe<Array<PropertyFile>>;
};

export type Query = {
  __typename?: "Query";
  bookmarkedProperties: Array<Property>;
  checkDevice: Scalars["Boolean"]["output"];
  checkVerificationOptions: CheckVerificationOptionsResponse;
  currencies: Array<Currency>;
  getAllConfigs: Array<SysConfig>;
  getBalance: Scalars["Int"]["output"];
  getBalanceObject: GetBalanceResponse;
  getCMSData: CmsDataOutput;
  getConfirmedPledges: Array<PropertyPledge>;
  getCreatorByCoupon: CouponCreatorResponse;
  getDashboardStats: DashboardResponse;
  getNotificationStatus: GetNotificationStatusResponse;
  getPaymentIntentStatus: GetPaymentIntentStatus;
  getPendingPledgeCount: Scalars["Float"]["output"];
  getPendingPledges: Array<PropertyPledge>;
  getPortfolioStats: PortfolioStats;
  getReferralsByReferrer: Array<Referral>;
  getTransactions: Array<Transaction>;
  getUpcomingRents: Array<UpcomingRentResponse>;
  investorInvites: Array<InvestorInvite>;
  isPropertyBookmarked: Scalars["Boolean"]["output"];
  listPaymentMethods: Array<PaymentMethod>;
  me: User;
  properties: Array<PropertyWithRoI>;
  property: Property;
  propertyTypes: Array<PropertyType>;
  publicProperties: Array<Property>;
  publicProperty: Property;
  settings?: Maybe<Settings>;
  sumsubToken: UserSumsubTokenResponse;
  sumsubWebSdkLink: Scalars["String"]["output"];
  sumsubWebSdkLinkFromParams: Scalars["String"]["output"];
  /** Checks if a user with the given email exists. */
  userExists: Scalars["Boolean"]["output"];
  users: Array<User>;
  validatePersonalInfo: ValidatePersonalInfoResponse;
  verifyDevice: Scalars["Boolean"]["output"];
  verifyPromoCode: CouponDto;
};

export type QueryCheckDeviceArgs = {
  deviceId: Scalars["String"]["input"];
};

export type QueryCheckVerificationOptionsArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetCreatorByCouponArgs = {
  code: Scalars["String"]["input"];
};

export type QueryGetDashboardStatsArgs = {
  data: DashboardRequest;
};

export type QueryGetNotificationStatusArgs = {
  deviceId: Scalars["String"]["input"];
};

export type QueryGetPaymentIntentStatusArgs = {
  paymentIntentId: Scalars["String"]["input"];
};

export type QueryGetPendingPledgesArgs = {
  paginationOptions?: InputMaybe<PaginationRequest>;
};

export type QueryGetTransactionsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryIsPropertyBookmarkedArgs = {
  propertyId: Scalars["String"]["input"];
};

export type QueryPropertiesArgs = {
  paginationOptions: PaginationRequest;
  where?: InputMaybe<WhereInput>;
};

export type QueryPropertyArgs = {
  id: Scalars["String"]["input"];
};

export type QueryPublicPropertiesArgs = {
  paginationOptions: PaginationRequest;
  where?: InputMaybe<WhereInput>;
};

export type QueryPublicPropertyArgs = {
  id: Scalars["String"]["input"];
};

export type QuerySettingsArgs = {
  appVersion: Scalars["String"]["input"];
};

export type QuerySumsubWebSdkLinkFromParamsArgs = {
  userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUserExistsArgs = {
  email: Scalars["String"]["input"];
};

export type QueryUsersArgs = {
  paginationOptions: PaginationRequest;
  where?: InputMaybe<WhereInput>;
};

export type QueryValidatePersonalInfoArgs = {
  data: ValidatePersonalInfoRequest;
};

export type QueryVerifyDeviceArgs = {
  deviceId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type QueryVerifyPromoCodeArgs = {
  code: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type Referral = {
  __typename?: "Referral";
  coupon?: Maybe<Coupon>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  referralDate?: Maybe<Scalars["DateTime"]["output"]>;
  referralRewardClaimed?: Maybe<Scalars["Boolean"]["output"]>;
  referredInvestor: Investor;
  referrerInvestor?: Maybe<Investor>;
  rewardStatus: ReferralRewardStatus;
  source: Source;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum ReferralRewardStatus {
  Claimed = "CLAIMED",
  Expired = "EXPIRED",
  Granted = "GRANTED",
  Pending = "PENDING",
}

export type ResendOtpResponse = {
  __typename?: "ResendOTPResponse";
  success: Scalars["Boolean"]["output"];
};

export type ResetPasswordInput = {
  code: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type Role = {
  __typename?: "Role";
  admins: Array<Admin>;
  assignments: Array<RoleAssignment>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum RoleAction {
  Add = "Add",
  Block = "Block",
  Delete = "Delete",
  List = "List",
  ListActive = "ListActive",
  ListAvailable = "ListAvailable",
  ListBlocked = "ListBlocked",
  ListCompleted = "ListCompleted",
  ListCustomer = "ListCustomer",
  ListDeposited = "ListDeposited",
  ListDepositedNotInvested = "ListDepositedNotInvested",
  ListEvangelist = "ListEvangelist",
  ListExited = "ListExited",
  ListHighBalance = "ListHighBalance",
  ListInactive = "ListInactive",
  ListInvested = "ListInvested",
  ListLead = "ListLead",
  ListMarketingQualifiedLead = "ListMarketingQualifiedLead",
  ListNew = "ListNew",
  ListOpportunity = "ListOpportunity",
  ListOther = "ListOther",
  ListReferrals = "ListReferrals",
  ListRented = "ListRented",
  ListSalesQualifiedLead = "ListSalesQualifiedLead",
  ListSubscriber = "ListSubscriber",
  ListUnverified = "ListUnverified",
  Unblock = "Unblock",
  UnverifiedReminderEmail = "UnverifiedReminderEmail",
  Update = "Update",
  View = "View",
}

export type RoleAssignment = {
  __typename?: "RoleAssignment";
  action: RoleAction;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  module: RoleModule;
  role: Role;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum RoleModule {
  Admin = "Admin",
  Analytics = "Analytics",
  Cities = "Cities",
  Cms = "Cms",
  Investor = "Investor",
  Leads = "Leads",
  Property = "Property",
  Referral = "Referral",
  Rewards = "Rewards",
  Role = "Role",
  Transaction = "Transaction",
}

export type SaveFcmTokenResponse = {
  __typename?: "SaveFcmTokenResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type SepaDebit = {
  __typename?: "SepaDebit";
  bank_code: Scalars["String"]["output"];
  branch_code: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  fingerprint: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
};

export type Settings = {
  __typename?: "Settings";
  appVersion: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  settings: Scalars["JSONObject"]["output"];
};

export type SetupIntentResponse = {
  __typename?: "SetupIntentResponse";
  client_secret: Scalars["String"]["output"];
};

export type Source = {
  __typename?: "Source";
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  referrals?: Maybe<Array<Referral>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SysConfig = {
  __typename?: "SysConfig";
  createdAt: Scalars["DateTime"]["output"];
  dataType: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["String"]["output"];
};

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["BigInt"]["output"];
  ccFee?: Maybe<Scalars["Decimal"]["output"]>;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  cryptoFxRate?: Maybe<Scalars["Decimal"]["output"]>;
  cryptoTag?: Maybe<Scalars["BigInt"]["output"]>;
  cryptoWallet?: Maybe<Scalars["String"]["output"]>;
  cryptofxRateWithMarkup?: Maybe<Scalars["Decimal"]["output"]>;
  currency: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  finalisedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  initiatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  initiatedBy: InitiatorType;
  initiatedByUser?: Maybe<Scalars["String"]["output"]>;
  paymentIntentId?: Maybe<Scalars["String"]["output"]>;
  paymentMethodId?: Maybe<Scalars["String"]["output"]>;
  paymentReferenceId?: Maybe<Scalars["String"]["output"]>;
  property?: Maybe<Property>;
  refundedAt?: Maybe<Scalars["DateTime"]["output"]>;
  refundedReason?: Maybe<Scalars["String"]["output"]>;
  relatedId?: Maybe<Scalars["String"]["output"]>;
  relatedType?: Maybe<Scalars["String"]["output"]>;
  solanaDepositTransactionId?: Maybe<Scalars["String"]["output"]>;
  solanaPropertyTransactionId?: Maybe<Scalars["String"]["output"]>;
  solanaSyncedAt?: Maybe<Scalars["DateTime"]["output"]>;
  solanaTransactionId?: Maybe<Scalars["String"]["output"]>;
  source: TransactionSource;
  status: TransactionStatus;
  stripeFee?: Maybe<Scalars["Decimal"]["output"]>;
  totalAmount?: Maybe<Scalars["Decimal"]["output"]>;
  type: TransactionType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export enum TransactionSource {
  BankTransfer = "BankTransfer",
  KingdomBank = "KingdomBank",
  Stripe = "Stripe",
}

export enum TransactionStatus {
  Canceled = "Canceled",
  Confirmed = "Confirmed",
  ErrorSolana = "ErrorSolana",
  Failed = "Failed",
  Pending = "Pending",
  PendingSolana = "PendingSolana",
  ProcessingSolana = "ProcessingSolana",
  Refunded = "Refunded",
}

export enum TransactionType {
  Coupon = "Coupon",
  Deposit = "Deposit",
  Pledge = "Pledge",
  RentDisbursement = "RentDisbursement",
  Withdrawal = "Withdrawal",
}

export type UpcomingRentResponse = {
  __typename?: "UpcomingRentResponse";
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  property: Property;
  sharePercentage: Scalars["Float"]["output"];
  totalRent: Scalars["Float"]["output"];
};

export type UpdateUserResponse = {
  __typename?: "UpdateUserResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type User = {
  __typename?: "User";
  adminProfile?: Maybe<Admin>;
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  devices?: Maybe<Array<Device>>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  investorProfile?: Maybe<Investor>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  marketingEmails?: Maybe<Scalars["Boolean"]["output"]>;
  partnerProfile?: Maybe<Partner>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status: UserStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userType: UserType;
  verifiedEmail?: Maybe<Scalars["Boolean"]["output"]>;
  verifiedPhone?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserRegisterRequest = {
  country: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  investmentApproach?: InputMaybe<Scalars["String"]["input"]>;
  investmentDuration?: InputMaybe<Scalars["Float"]["input"]>;
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type UserRegisterResponse = {
  __typename?: "UserRegisterResponse";
  accessToken: Scalars["String"]["output"];
  adminProfile?: Maybe<Admin>;
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  devices?: Maybe<Array<Device>>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  investorProfile?: Maybe<Investor>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  marketingEmails?: Maybe<Scalars["Boolean"]["output"]>;
  partnerProfile?: Maybe<Partner>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status: UserStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userType: UserType;
  verifiedEmail?: Maybe<Scalars["Boolean"]["output"]>;
  verifiedPhone?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserResponse = {
  __typename?: "UserResponse";
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export enum UserStatus {
  Active = "Active",
  Blocked = "Blocked",
  Unverified = "Unverified",
  VerificationInProgress = "VerificationInProgress",
}

export type UserSumsubTokenResponse = {
  __typename?: "UserSumsubTokenResponse";
  accessToken: Scalars["String"]["output"];
};

export enum UserType {
  Admin = "Admin",
  Investor = "Investor",
  Partner = "Partner",
}

export type UserUpdateRequest = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValidatePersonalInfoRequest = {
  country: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type ValidatePersonalInfoResponse = {
  __typename?: "ValidatePersonalInfoResponse";
  success: Scalars["Boolean"]["output"];
};

export type VerifyOtpResponse = {
  __typename?: "VerifyOTPResponse";
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  token: Scalars["String"]["output"];
  user: User;
};

export type WhereInput = {
  filters?: InputMaybe<Array<FilterInput>>;
};

export type GetSettingsQueryVariables = Exact<{
  appVersion: Scalars["String"]["input"];
}>;

export type GetSettingsQuery = {
  __typename?: "Query";
  settings?: {
    __typename?: "Settings";
    appVersion: string;
    settings: any;
  } | null;
};

export type RequestAccountDeletionMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestAccountDeletionMutation = {
  __typename?: "Mutation";
  requestAccountDeletion: boolean;
};

export type RegisterInterestMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  utm_url: Scalars["String"]["input"];
  utm_campaign?: InputMaybe<Scalars["String"]["input"]>;
  utm_medium?: InputMaybe<Scalars["String"]["input"]>;
  utm_source?: InputMaybe<Scalars["String"]["input"]>;
  utm_content?: InputMaybe<Scalars["String"]["input"]>;
  utm_term?: InputMaybe<Scalars["String"]["input"]>;
  utm_id?: InputMaybe<Scalars["String"]["input"]>;
  preferredPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  expectInvestmentAmount?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RegisterInterestMutation = {
  __typename?: "Mutation";
  registerInterest: string;
};

export type SendOtpMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
  deviceId: Scalars["String"]["input"];
  method: Scalars["String"]["input"];
}>;

export type SendOtpMutation = { __typename?: "Mutation"; sendOTP: string };

export type VerifyOtpMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
  deviceId: Scalars["String"]["input"];
  otp: Scalars["String"]["input"];
  method: Scalars["String"]["input"];
}>;

export type VerifyOtpMutation = {
  __typename?: "Mutation";
  verifyOTP: {
    __typename?: "VerifyOTPResponse";
    token: string;
    lastLogin?: any | null;
    user: { __typename?: "User"; id: string; email: string };
  };
};

export type VerifyOtpToPhoneMutationVariables = Exact<{
  phone: Scalars["String"]["input"];
  otp: Scalars["String"]["input"];
}>;

export type VerifyOtpToPhoneMutation = {
  __typename?: "Mutation";
  verifyOTPToPhone: { __typename?: "UpdateUserResponse"; success: boolean };
};

export type ResendOtpToPhoneMutationVariables = Exact<{
  phone: Scalars["String"]["input"];
}>;

export type ResendOtpToPhoneMutation = {
  __typename?: "Mutation";
  resendOTPToPhone: { __typename?: "ResendOTPResponse"; success: boolean };
};

export type UpdateUserMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "UpdateUserResponse";
    success: boolean;
    message?: string | null;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: boolean;
};

export type GetCreatorByCouponQueryVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type GetCreatorByCouponQuery = {
  __typename?: "Query";
  getCreatorByCoupon: {
    __typename?: "CouponCreatorResponse";
    isPromoValid: boolean;
    code?: string | null;
    creator?: {
      __typename?: "CreatorResponse";
      user?: {
        __typename?: "UserResponse";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
      } | null;
    } | null;
  };
};

export type CheckDeviceQueryVariables = Exact<{
  deviceId: Scalars["String"]["input"];
}>;

export type CheckDeviceQuery = { __typename?: "Query"; checkDevice: boolean };

export type GetPropertyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPropertyTypesQuery = {
  __typename?: "Query";
  propertyTypes: Array<{
    __typename?: "PropertyType";
    id: string;
    name: string;
  }>;
};

export type GetPropertiesQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WhereInput>;
}>;

export type GetPropertiesQuery = {
  __typename?: "Query";
  publicProperties: Array<{
    __typename?: "Property";
    id: string;
    title: string;
    description: string;
    excerpt: string;
    code: string;
    price: any;
    location?: any | null;
    bed: number;
    status: PropertyStatus;
    bath: number;
    sqFootage: number;
    transactionFee: any;
    platformFee: any;
    slug: string;
    isFunded: boolean;
    order: number;
    manualFunded?: any | null;
    manualFundedDays?: any | null;
    finalInvestorsCount: number;
    createdAt: any;
    type?: { __typename?: "PropertyType"; id: string } | null;
    city: { __typename?: "City"; name: string };
    amenities?: Array<{ __typename?: "PropertyAmenity"; title: string }> | null;
    timelineEvents: Array<{
      __typename?: "PropertyTimeline";
      title: string;
      description: string;
    }>;
    financials: Array<{
      __typename?: "PropertyFinancials";
      projectedAnnualRoIPercentage: any;
      projectedAnnualRoIValue: any;
      year3ProjectedAnnualRoIPercentage: any;
      year3ProjectedAnnualRoIValue: any;
      annualRent: any;
      effectiveDate: any;
      endDate?: any | null;
    }>;
    rents: Array<{
      __typename?: "PropertyRent";
      date?: any | null;
      rent: any;
      isPaid: boolean;
      isDisbursed: boolean;
    }>;
    images?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      order: number;
    }> | null;
    pledges: Array<{
      __typename?: "PropertyPledge";
      id: string;
      status: PropertyPledgeStatus;
      amount: any;
    }>;
  }>;
};

export type GetPropertyNamesQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WhereInput>;
}>;

export type GetPropertyNamesQuery = {
  __typename?: "Query";
  publicProperties: Array<{
    __typename?: "Property";
    id: string;
    title: string;
  }>;
};

export type GetPropertyQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetPropertyQuery = {
  __typename?: "Query";
  publicProperty: {
    __typename?: "Property";
    id: string;
    title: string;
    description: string;
    code: string;
    price: any;
    location?: any | null;
    transactionFee: any;
    platformFee: any;
    isRented: boolean;
    isFunded: boolean;
    bed: number;
    bath: number;
    sqFootage: number;
    manualFunded?: any | null;
    manualFundedDays?: any | null;
    finalInvestorsCount: number;
    createdAt: any;
    city: {
      __typename?: "City";
      name: string;
      excerpt?: string | null;
      description?: string | null;
      propertyDescription?: string | null;
      location?: any | null;
    };
    images?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      order: number;
    }> | null;
    videos?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      order: number;
    }> | null;
    documents?: Array<{
      __typename?: "PropertyFile";
      id: string;
      filename: string;
      ext: string;
    }> | null;
    amenities?: Array<{ __typename?: "PropertyAmenity"; title: string }> | null;
    timelineEvents: Array<{
      __typename?: "PropertyTimeline";
      title: string;
      description: string;
      date: any;
    }>;
    financials: Array<{
      __typename?: "PropertyFinancials";
      projectedAnnualRoIPercentage: any;
      projectedAnnualRoIValue: any;
      year3ProjectedAnnualRoIPercentage: any;
      year3ProjectedAnnualRoIValue: any;
      annualRent: any;
      effectiveDate: any;
      endDate?: any | null;
    }>;
    rents: Array<{
      __typename?: "PropertyRent";
      date?: any | null;
      rent: any;
      isPaid: boolean;
      isDisbursed: boolean;
    }>;
    pledges: Array<{
      __typename?: "PropertyPledge";
      id: string;
      amount: any;
      status: PropertyPledgeStatus;
    }>;
  };
};

export type GetSumsubWebSdkLinkFromParamsQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type GetSumsubWebSdkLinkFromParamsQuery = {
  __typename?: "Query";
  sumsubWebSdkLinkFromParams: string;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: "Query";
  me: {
    __typename?: "User";
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    status: UserStatus;
    investorProfile?: {
      __typename?: "Investor";
      id: string;
      solanaWalletAddress?: string | null;
    } | null;
  };
};

export type RegisterUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  investmentDuration?: InputMaybe<Scalars["Float"]["input"]>;
  investmentApproach?: InputMaybe<Scalars["String"]["input"]>;
  deviceId: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  deviceType: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
}>;

export type RegisterUserMutation = {
  __typename?: "Mutation";
  register: {
    __typename?: "UserRegisterResponse";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type SubmitPropertyMutationVariables = Exact<{
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  bedrooms: Scalars["Int"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
}>;

export type SubmitPropertyMutation = {
  __typename?: "Mutation";
  submitProperty: { __typename?: "Property"; id: string };
};

export const GetSettingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSettings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "appVersion" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "settings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "appVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "appVersion" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "appVersion" } },
                { kind: "Field", name: { kind: "Name", value: "settings" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSettingsQuery, GetSettingsQueryVariables>;
export const RequestAccountDeletionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestAccountDeletion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestAccountDeletion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestAccountDeletionMutation,
  RequestAccountDeletionMutationVariables
>;
export const RegisterInterestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "registerInterest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phoneNumber" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_url" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_campaign" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_medium" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_source" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_content" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_term" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "utm_id" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredPropertyId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "expectInvestmentAmount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerInterest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fullName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fullName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phoneNumber" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_url" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_url" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_campaign" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_campaign" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_medium" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_medium" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_source" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_source" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_content" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_content" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_term" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_term" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "utm_id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "utm_id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "preferredPropertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "preferredPropertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "expectInvestmentAmount" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "expectInvestmentAmount" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterInterestMutation,
  RegisterInterestMutationVariables
>;
export const SendOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendOTP" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendOtpMutation, SendOtpMutationVariables>;
export const VerifyOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "otp" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "method" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyOTP" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "otp" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otp" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "method" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "method" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "token" } },
                { kind: "Field", name: { kind: "Name", value: "lastLogin" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const VerifyOtpToPhoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyOTPToPhone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "otp" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyOTPToPhone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "otp" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otp" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyOtpToPhoneMutation,
  VerifyOtpToPhoneMutationVariables
>;
export const ResendOtpToPhoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resendOTPToPhone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resendOTPToPhone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResendOtpToPhoneMutation,
  ResendOtpToPhoneMutationVariables
>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phone" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phone" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const ChangePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "changePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "oldPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "oldPassword" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "oldPassword" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "newPassword" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "newPassword" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const GetCreatorByCouponDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCreatorByCoupon" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCreatorByCoupon" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "code" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isPromoValid" },
                },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creator" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCreatorByCouponQuery,
  GetCreatorByCouponQueryVariables
>;
export const CheckDeviceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "checkDevice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkDevice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckDeviceQuery, CheckDeviceQueryVariables>;
export const GetPropertyTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPropertyTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyTypesQuery,
  GetPropertyTypesQueryVariables
>;
export const GetPropertiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProperties" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publicProperties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                { kind: "Field", name: { kind: "Name", value: "bed" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "bath" } },
                { kind: "Field", name: { kind: "Name", value: "sqFootage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionFee" },
                },
                { kind: "Field", name: { kind: "Name", value: "platformFee" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "isFunded" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFunded" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFundedDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalInvestorsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amenities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "timelineEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financials" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annualRent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      { kind: "Field", name: { kind: "Name", value: "rent" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPaid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isDisbursed" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      { kind: "Field", name: { kind: "Name", value: "order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pledges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetPropertyNamesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPropertyNames" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publicProperties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyNamesQuery,
  GetPropertyNamesQueryVariables
>;
export const GetPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "publicProperty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionFee" },
                },
                { kind: "Field", name: { kind: "Name", value: "platformFee" } },
                { kind: "Field", name: { kind: "Name", value: "isRented" } },
                { kind: "Field", name: { kind: "Name", value: "isFunded" } },
                { kind: "Field", name: { kind: "Name", value: "bed" } },
                { kind: "Field", name: { kind: "Name", value: "bath" } },
                { kind: "Field", name: { kind: "Name", value: "sqFootage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFunded" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFundedDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finalInvestorsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "excerpt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "propertyDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      { kind: "Field", name: { kind: "Name", value: "order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      { kind: "Field", name: { kind: "Name", value: "order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amenities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "timelineEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financials" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annualRent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      { kind: "Field", name: { kind: "Name", value: "rent" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPaid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isDisbursed" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pledges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyQuery, GetPropertyQueryVariables>;
export const GetSumsubWebSdkLinkFromParamsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSumsubWebSdkLinkFromParams" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sumsubWebSdkLinkFromParams" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSumsubWebSdkLinkFromParamsQuery,
  GetSumsubWebSdkLinkFromParamsQueryVariables
>;
export const GetMeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getMe" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "investorProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "solanaWalletAddress" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMeQuery, GetMeQueryVariables>;
export const RegisterUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "registerUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "country" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "investmentDuration" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "investmentApproach" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "osVersion" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "model" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "register" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phone" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phone" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "country" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "country" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "investmentDuration" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "investmentDuration" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "investmentApproach" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "investmentApproach" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceData" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deviceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "osVersion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "osVersion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deviceType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "model" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "model" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const SubmitPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "submitProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bedrooms" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lat" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lng" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitProperty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "address" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "address" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "city" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "city" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bedrooms" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bedrooms" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phone" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phone" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lat" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lat" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lng" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lng" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitPropertyMutation,
  SubmitPropertyMutationVariables
>;
