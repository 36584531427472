import { useLazyQuery } from "@apollo/client";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";
import { QUERY_GET_PROPERTY_LIST } from "../../../graphql/queries/property";
import { QUERY_GET_PROPERTY_TYPES } from "../../../graphql/queries/property-types";
import { PropertyCard } from "../../../components/property/card";
import ImgMarker from "../../../assets/img/common/favicon.png";
import CardSkeleton from "../../../components/skeleton/cardskeleton";
import BoxSkeleton from "../../../components/skeleton/boxskeleton";
import { Property, PropertyType } from "../../../__generated__/graphql";
import { useSettings } from "../../../context/settings";
import { t } from "../../../utils/cms";

const FILTER = {
  available: {
    filters: [
      { field: "status", operator: "in", value: '["active", "coming_soon"]' },
    ],
  },
  funded: { filters: [{ field: "status", operator: "=", value: "completed" }] },
  exited: {
    filters: [{ field: "deleted_at", operator: "!=", value: "2012-01-01" }],
  },
  coming_soon: {
    filters: [{ field: "status", operator: "=", value: "coming_soon" }],
  },
};

enum ViewState {
  Grid = "grid",
  Map = "map",
}

const PER_PAGE = 10;

export function PropertyMarketplacePage() {
  const [selectedTab, setSelectedTab] = useState<
    "available" | "funded" | "exited" | "coming_soon"
  >("available");
  const [selectedType, setSelectedType] = useState<PropertyType | null>(null);
  const [selectedView, setSelectedView] = useState<ViewState>(ViewState.Grid);

  const [getProperties, { loading, error, data }] = useLazyQuery(
    QUERY_GET_PROPERTY_LIST,
    { errorPolicy: "all" }
  );
  const [
    getTypes,
    { data: typesData, loading: loadingtypes, error: errortypes },
  ] = useLazyQuery(QUERY_GET_PROPERTY_TYPES, { errorPolicy: "all" });

  const [properties, setProperties] = useState<any[]>();
  const { settings } = useSettings();

  useEffect(() => {
    if (data) {
      const allProperties = [...data.publicProperties];
      if (selectedType === null) {
        setProperties(allProperties);
      } else {
        const filteredProperties = allProperties.filter(
          (property: Property) => property?.type?.id === selectedType.id
        );
        setProperties(filteredProperties);
      }
    }
  }, [data, selectedType]);

  useEffect(() => {
    getProperties({
      variables: { take: PER_PAGE, skip: 0, where: FILTER[selectedTab] },
    });
  }, [selectedTab]);

  useEffect(() => {
    getTypes();
  }, []);

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: ["places", "marker"],
  });

  const map = useRef<google.maps.Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [markers, setMarkers] = useState<
    google.maps.marker.AdvancedMarkerElement[]
  >([]);
  const [selectedProperty, setSelectedProperty] = useState<string>();

  useEffect(() => {
    if (mapLoaded && properties) {
      // Hide markers until real properties are in place
      applyMarkers();
    }
  }, [properties, mapLoaded]);

  function applyMarkers() {
    if (!properties || properties.length === 0) {
      setMarkers([]);
      return;
    }

    const _markers = properties
      .filter((property) => property.location)
      .map(
        (property: any) =>
          new google.maps.marker.AdvancedMarkerElement({
            position: new google.maps.LatLng(
              property.location.coordinates[1],
              property.location.coordinates[0]
            ),
            map: map.current,
            content: createMarkerContent(property),
          })
      );
    setMarkers(_markers);
    if (_markers.length === 0) {
      return;
    } else if (_markers.length === 1) {
      const coordinates = properties.filter((property) => property.location)[0]
        .location.coordinates;
      map.current?.setCenter(
        new google.maps.LatLng(coordinates[1], coordinates[0])
      );
    } else {
      map.current?.fitBounds(
        properties.reduce((bounds, property) => {
          if (property.location) {
            bounds.extend(
              new google.maps.LatLng(
                property.location.coordinates[1],
                property.location.coordinates[0]
              )
            );
          }
          return bounds;
        }, new google.maps.LatLngBounds())
      );
    }

    _markers.forEach((marker) => {
      marker.addListener("click", () => {
        setSelectedProperty(
          (marker.content?.firstChild as HTMLDivElement).getAttribute(
            "data-id"
          )!
        );
      });
    });
  }

  const createMarkerContent = (property: any) => {
    const markerContent = document.createElement("div");
    const root = createRoot(markerContent);
    root.render(<MarkerContent {...property} />);
    return markerContent;
  };

  function onMapLoad(_map: google.maps.Map) {
    setMapLoaded(true);
    map.current = _map;

    if (mapLoaded && properties) {
      // Hide markers until real properties are in place
      applyMarkers();
    }
  }

  function handleTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedTypeId = event.target.value;
    if (selectedTypeId === "all") {
      setSelectedType(null);
    } else {
      const selectedType =
        typesData?.propertyTypes.find(
          (type: PropertyType) => type.id === selectedTypeId
        ) || null;
      setSelectedType(selectedType);
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("marketplace.Page Metadata.page_title_marketplace")}</title>
        <meta
          name="description"
          content={t("marketplace.Page Metadata.meta_description_marketplace")}
        />
      </Helmet>

      <Nav />

      <Content className="marketplace">
        <div className="header" style={{ paddingTop: 20, paddingBottom: 80 }}>
          <h2 style={{ marginTop: 50, marginBottom: 20, color: "#ffffff" }}>
            {t("marketplace.Page Content.header_title_marketplace")}
          </h2>
          {settings?.blurProperties && (
            <h3
              style={{
                marginTop: 50,
                marginBottom: 20,
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              {t("marketplace.Page Content.coming_soon_title_marketplace")}
            </h3>
          )}
          <p
            style={{
              maxWidth: 850,
              color: "#bec4d0",
              fontWeight: 400,
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            {t("marketplace.Page Content.header_description_marketplace")}
          </p>
        </div>
        <div className="filter-container">
          <select
            className="dropdown"
            value={selectedView}
            onChange={(e) => setSelectedView(e.target.value as ViewState)}
          >
            <option value="grid">
              {t("marketplace.View Options.view_options_grid_marketplace")}
            </option>
            <option value="map">
              {t("marketplace.View Options.view_options_map_marketplace")}
            </option>
          </select>
          <div className="tabs-container">
            <div className="tabs">
              <div
                className={`tab ${selectedTab === "available" ? "active" : ""}`}
                onClick={() => setSelectedTab("available")}
              >
                {t("marketplace.Tabs.tabs_available_marketplace")}
              </div>
              {/* <div className={`tab ${selectedTab === 'coming_soon' ? 'active' : '}`} onClick={() => setSelectedTab('coming_soon')}>
                Coming Soon
              </div> */}
              <div
                className={`tab ${selectedTab === "funded" ? "active" : ""}`}
                onClick={() => setSelectedTab("funded")}
              >
                {t("marketplace.Tabs.tabs_funded_marketplace")}
              </div>
              <div
                className={`tab ${selectedTab === "exited" ? "active" : ""}`}
                onClick={() => setSelectedTab("exited")}
              >
                {t("marketplace.Tabs.tabs_exited_marketplace")}
              </div>
            </div>
          </div>
          <select
            className="dropdown"
            value={selectedType?.id || "all"}
            onChange={handleTypeChange}
          >
            <option value="all">
              {t("marketplace.Filter Options.property_types_marketplace")}
            </option>
            {typesData?.propertyTypes.map((type: PropertyType) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div className="content">
          <div
            className={`property-cards ${selectedView === ViewState.Grid ? "grid" : ""}`}
          >
            {loading &&
              Array.from({ length: PER_PAGE }).map((_, index) => (
                <CardSkeleton key={index} />
              ))}
            {!loading && properties?.length === 0 && (
              <div>
                <b>
                  {t(
                    "marketplace.Status Messages.no_properties_message_marketplace"
                  )}
                </b>
              </div>
            )}
            {!loading &&
              properties?.map((property: any) => (
                <PropertyCard
                  key={property.id}
                  property={property}
                  showFinancials={true}
                  selectedTab={selectedTab}
                  selected={selectedProperty === property.id}
                />
              ))}
          </div>
          {selectedView === ViewState.Map && (
            <>
              {loading && <BoxSkeleton width={800} height={600} />}
              {!loading && isGoogleApiLoaded && (
                <div className="map">
                  <GoogleMap
                    mapTypeId="roadmap"
                    ref={map as React.RefObject<GoogleMap>}
                    mapContainerStyle={{
                      flex: 10,
                      height: 800,
                      width: "100%",
                      borderWidth: 1,
                    }}
                    center={{ lat: 51.5041705, lng: -0.124267 }}
                    zoom={12}
                    options={{
                      mapId: "f93dc101cf6ad224",
                      disableDefaultUI: true,
                      zoomControl: true,
                    }}
                    onLoad={onMapLoad}
                  ></GoogleMap>
                </div>
              )}
            </>
          )}
        </div>
      </Content>

      <Footer />
    </>
  );
}

const MarkerContent = ({ id, title }: { id: string; title: string }) => (
  <div className="marker" data-id={id}>
    <img src={ImgMarker} alt="marker" />
    <div className="text">
      <div className="name">{title}</div>
    </div>
  </div>
);
