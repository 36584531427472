import { useEffect } from "react";
import { ReactComponent as ImgAppStore } from "../../assets/img/common/app-store.svg";
import { ReactComponent as ImgPlayStore } from "../../assets/img/common/play-store.svg";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  data: any;
  loading?: boolean;
}

const PromoPopupPage = ({
  show = false,
  setShow,
  data: creatorData,
  loading = false,
}: Props) => {
  const firstName = creatorData?.creator?.user?.firstName;
  const lastName = creatorData?.creator?.user?.lastName;
  const creatorEmail = creatorData?.creator?.user?.email;
  const fullName = firstName + " " + lastName;

  return (
    <>
      <div
        className={`modal-overlay ${show ? "show" : ""}`}
        onClick={() => {
          setShow(false);
        }}
      ></div>
      {!loading && creatorData?.isPromoValid ? (
        <div className={`promo-popup ${show ? "show" : ""}`}>
          <button
            className="btn btn-close"
            onClick={() => {
              setShow(false);
            }}
          >
            X
          </button>
          <h4 className="popup-title">You've Been Invited!</h4>
          <div className="separation-line"></div>
          <div className="content">
            <div>
              <h4 className="form-heading">Invited By</h4>
              <div className="promo-details">
                <div className="flex">
                  <div className="invitor-profile">
                    {firstName?.charAt(0).toUpperCase()}
                  </div>
                  <h3 className="inviter-name">{fullName}</h3>
                </div>
                <p>{creatorEmail}</p>
              </div>
            </div>

            <h4 className="form-heading">About Propnerd</h4>
            <p>
              PropNerd is your ultimate investment platform. Join now and claim
              your exclusive rewards!
            </p>
            <div className="separation-line"></div>

            <div className="promo-details">
              <h4 className="form-heading">Your Coupon Code</h4>
              <div className="code-container">
                <p className="promo-code">{creatorData?.code}</p>
              </div>
            </div>

            <div className="separation-line"></div>

            <div className="store-links m-justify-center flex gap-lg">
              <a
                href={`${process.env.REACT_APP_INVESTOR_PORTAL_URL}/account/register`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-signup"
              >
                Sign Up
              </a>
            </div>
            <div className="store-links m-justify-between flex ">
              <p className="text">Download App Now</p>
              <div className="flex app-links">
                <a
                  href="https://apps.apple.com/app/propnerd/id6497331931"
                  target="_blank"
                  className="animate__animated animate__backInLeft animate__delay-2s"
                >
                  <ImgAppStore width={150} height={50} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=io.propnerd.investor"
                  target="_blank"
                  className="animate__animated animate__backInLeft animate__delay-2s"
                >
                  <ImgPlayStore
                    width={158}
                    height={50}
                    className="animate__animated animate__backInLeft animate__delay-2s"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        !loading && (
          <div className={`promo-popup invalid-promo ${show ? "show" : ""}`}>
            <button
              className="btn btn-close"
              onClick={() => {
                setShow(false);
              }}
            >
              X
            </button>
            <h4 className="popup-title">Promo Code Invalid</h4>
            <div className="separation-line"></div>
            <div className="content">
              <p className="form-heading invalid-form-heading">
                We couldn't verify your promo code.
              </p>
              <p>
                Please ensure that you have correct invitaion link or try again
                later.
              </p>
              <div className="separation-line"></div>

              <div className="action-options">
                <h4 className="form-heading">Need Assistance?</h4>
                <p>
                  Reach out to our{" "}
                  <a href="mailto:support@propnerd.io">support team</a>, or try
                  registering directly on our platform without the promo code.
                </p>
              </div>
              <div className="store-links m-justify-center flex gap-lg">
                <a
                  href={`${process.env.REACT_APP_INVESTOR_PORTAL_URL}/account/register`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-signup"
                >
                  Sign Up
                </a>
              </div>
              <div className="store-links m-justify-between flex">
                <p className="text">Download App Now</p>
                <div className="flex app-links">
                  <a
                    href="https://apps.apple.com/app/propnerd/id6497331931"
                    target="_blank"
                    className="animate__animated animate__backInLeft animate__delay-2s"
                  >
                    <ImgAppStore width={150} height={50} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=io.propnerd.investor"
                    target="_blank"
                    className="animate__animated animate__backInLeft animate__delay-2s"
                  >
                    <ImgPlayStore width={158} height={50} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default PromoPopupPage;
